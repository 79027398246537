import dayjs from "dayjs"
import localeHr from 'dayjs/locale/hr'
import localeLt from 'dayjs/locale/lt'
import localeEn from 'dayjs/locale/en'

const locales = {
	hr: localeHr,
	lt: localeLt,
	en: localeEn,
}

import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

// Register dayjs globally
window.dayjs = dayjs

export function setLocale(locale){
	return dayjs.locale(locales[locale])
}